const isProd = true;

export const getPersonalDataLink = () => {
  const actPrefix = isProd ? '' : 'act-liberty-';

  return `https://${actPrefix}www.yelumseguros.com.br/Pages/atendimento/dados-pessoais.aspx`;
};

export const getMeuMomentoResidenciaLink = () => {
  const actPrefix = isProd ? '' : 'act-liberty-';

  return `https://${actPrefix}www.meumomentoresidencia.yelumseguros.com.br/`;
};

export const addHttp = (link: string) => {
  let url = link;

  if (!/^(f|ht)tps?:\/\//i.test(url)) {
    url = `http://${url}`;
  }
  return url;
};

export const getAssistancyDetailLink = () => {
  const actPrefix = isProd ? '' : 'act-liberty-';
  return `https://${actPrefix}www.yelumseguros.com.br/Pages/seguros/Ve%C3%ADculos/Auto-Perfil.aspx`;
};

export const getContactUsLink = () => {
  const actPrefix = isProd ? '' : 'act-liberty-';
  return `https://${actPrefix}www.yelumseguros.com.br/Pages/atendimento/fale-conosco.aspx`;
};

export const getAboutUsLink = () => {
  const actPrefix = isProd ? '' : 'act-liberty-';
  return `https://${actPrefix}www.yelumseguros.com.br/Pages/sobre-a-liberty.aspx`;
};

export const getCompanyWebsite = () => {
  const actPrefix = isProd ? '' : 'act-liberty-';
  return `https://${actPrefix}www.yelumseguros.com.br`;
};

export const getVehicleFranchiseLink = () => {
  // const actPrefix = isProd ? '' : 'act-liberty-';
  // return `https://${actPrefix}www.yelumseguros.com.br/Pages/atendimento/Ajuda/Franquia-do-veiculo.aspx`;
  return 'https://www.yelumseguros.com.br/Pages/atendimento/Ajuda/Franquia-do-veiculo.aspx'
};

export const getCustomerSpaceLink = () => {
  // const actPrefix = isProd ? '' : 'act-';
  return ` https://meuespaco.yelumseguros.com.br/SitePages/Desconectado/Apolice/OQueMeuSeguroCobre.aspx`;
};

export const getActivateClaimLink = () => {
  // const actPrefix = isProd ? '' : 'act-';
  return `https://meuespaco.yelumseguros.com.br/SitePages/Desconectado/Sinistro/AvisarSinistro.aspx`;
};

export const getGlassAssistanceLink = () => {
  // const actPrefix = isProd ? '' : 'act-';
  return ` https://www.yelumseguros.com.br/Pages/atendimento/Ajuda/assistencia-vidros-automovel.aspx`;
};

export const getWhatsappLink = () => {
  return `https://api.whatsapp.com/send?1=pt_BR&phone=551132061414&text=Oi%20Liberty,%20Tudo%20Bem%20?`;
};

export const getVehicleInspectionLink = () => {
  // const actPrefix = isProd ? '' : 'act-liberty-';
  return 'https://www.yelumseguros.com.br/Pages/atendimento/Ajuda/Informa%C3%A7%C3%B5es-sobre-Vistoria-do-ve%C3%ADculo.aspx';
  // return `https://${actPrefix}www.yelumseguros.com.br/Pages/atendimento/Ajuda/Informa%C3%A7%C3%B5es-sobre-Vistoria-do-ve%C3%ADculo.aspx`;
};
