/* eslint-disable no-param-reassign */
import axios from 'axios';
import { LOCAL_BROKER } from 'config';

import appConfig from '../assets/settings/appConfig.UAT.json'

const brokerDataLocalStorage = window.localStorage.getItem(LOCAL_BROKER);

const api = axios.create({
  baseURL: `${appConfig.REACT_APP_HOST}`,
  timeout: 1200000,
});

api.defaults.headers.common.Authorization = brokerDataLocalStorage
  ? `${JSON.parse(brokerDataLocalStorage).jwt}`
  : '';

export const refreshToken = async (): Promise<string> =>
  new Promise((resolve, reject) => {
    api
      .post('/auth/login', {
        client_id: appConfig.REACT_APP_CLIENT_ID,
        client_secret: appConfig.REACT_APP_CLIENT_SECRET,
      })
      .then(async res => {
        // saves new token
        const accessToken = res.data?.access_token;
        if (accessToken) {
          // Cookies.set(LOCAL_TOKEN, accessToken);
          api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
        }
        return resolve(accessToken);
      })
      .catch(err => reject(err));
  });

// intercepts response on 401 (token)
api.interceptors.response.use(
  res => res,
  ({ response }) => (response.status === 401 ? refreshToken() : response)
)

export default api;
