/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
// eslint-disable-next-line no-unused-vars
import React, { Fragment, useCallback } from 'react';

import { useSearchLogo } from 'hooks/useSearchLogo';
import { useLocation } from 'react-router-dom';

import { useWindowSize } from 'helpers/custom-hooks';
import { mobileMinWidthInt } from 'styles/variables';
import {
  getAboutUsLink,
  getCompanyWebsite,
  getPersonalDataLink,
  getContactUsLink
} from 'helpers/links';
import { googleAnalyticsEvent } from 'helpers/googleTagManager';
import appConfig from '../../../assets/settings/appConfig.UAT.json'

import * as S from './styles';

export default function Footer() {
  const [logo] = useSearchLogo();
  const [width] = useWindowSize();
  const location = useLocation();
  const listBrokerFooter = [
    '/',
    '/contact',
    '/faq',
    '/contract',
    '/error',
    '/recommendation',
    '/success',
  ];

  const handleGoogleEvent = useCallback((button: string) => {
    googleAnalyticsEvent({
      dataLayer: {
        location: 'Footer',
        button,
        event: 'Clique',
      },
    });
  }, []);

  return (
    <footer>
      {listBrokerFooter.includes(location.pathname) && (
        <S.ContainerLogo>
          <S.Logo src={logo} alt="Imagem de um logo." />
        </S.ContainerLogo>
      )}

      <S.Wrapper>
        <S.LimitWrapper>
          <S.Content>
            <S.Title>
              Meu Momento Auto é uma plataforma da{' '}
              {window.innerWidth <= mobileMinWidthInt && <br />}
              <b>{appConfig.COMPANY_FULL_NAME}</b>, uma{' '}
              {window.innerWidth > mobileMinWidthInt && <br />} seguradora
              que torna {window.innerWidth <= mobileMinWidthInt && <br />}
              a sua segurança única, feita para você viver com liberdade.
            </S.Title>
            <S.Subtitle>
              Nós existimos para ajudar as pessoas a aproveitar <br />o hoje e
              buscar amanhã com confiança.
            </S.Subtitle>
            <S.CompanyCopyright
              style={{ display: width <= mobileMinWidthInt ? 'none' : 'block' }}
            >
              © 2024 {appConfig.COMPANY_FULL_NAME}. Todos os direitos reservados.
            </S.CompanyCopyright>
            <S.CompanyLawMessage
              style={{ display: width <= mobileMinWidthInt ? 'none' : 'block' }}
            >
              A {appConfig.COMPANY_FULL_NAME} está adequado a Lei Geral de Proteção de
              Dados (Lei No. 13.709, de 14 de Agosto de 2018).{' '}
              <a
                target="_blank"
                onClick={() => handleGoogleEvent('Clique aqui')}
                href={getPersonalDataLink()}
                rel="noopener noreferrer"
              >
                Clique aqui
              </a>{' '}
              e saiba como tratamos seus dados pessoais e conheça nossos Termos
              e Condições de uso do site e Política de Privacidade.
            </S.CompanyLawMessage>
          </S.Content>
          <S.Content>
            <S.Lists>
              <S.List>
                <S.ListTitle>Explore</S.ListTitle>
                <S.ListItem>
                  <a
                    target="_blank"
                    onClick={() => handleGoogleEvent('Proteção de dados')}
                    href={getPersonalDataLink()}
                    rel="noopener noreferrer"
                    style={{ color: '#fff', textDecoration: 'none' }}
                  >
                    Proteção de dados
                  </a>
                </S.ListItem>
              </S.List>
              <S.List>
                <S.ListTitle>{appConfig.COMPANY_FULL_NAME}</S.ListTitle>
                <S.ListItem>
                  <a
                    target="_blank"
                    onClick={() => handleGoogleEvent('Sobre a Liberty')}
                    href={getAboutUsLink()}
                    rel="noopener noreferrer"
                    style={{ color: '#fff', textDecoration: 'none' }}
                  >
                    Sobre a {appConfig.COMPANY_NAME}
                  </a>
                </S.ListItem>
                <S.ListItem>
                  <a
                    target="_blank"
                    onClick={() => handleGoogleEvent('Contato')}
                    href={getContactUsLink()}
                    rel="noopener noreferrer"
                    style={{ color: '#fff', textDecoration: 'none' }}
                  >
                    Contato
                  </a>
                </S.ListItem>
              </S.List>
            </S.Lists>
            <S.CompanyCopyright
              style={{ display: width <= mobileMinWidthInt ? 'block' : 'none' }}
            >
              ©2024 {appConfig.COMPANY_FULL_NAME}. Todos os direitos reservados.
            </S.CompanyCopyright>
            <S.CompanyLawMessage
              style={{ display: width <= mobileMinWidthInt ? 'block' : 'none' }}
            >
              A {appConfig.COMPANY_FULL_NAME} está adequado a Lei Geral de Proteção de
              Dados (Lei No. 13.709, de 14 de Agosto de 2018).{' '}
              <a
                target="_blank"
                onClick={() => handleGoogleEvent('Clique aqui')}
                href={getPersonalDataLink()}
                rel="noopener noreferrer"
              >
                Clique aqui
              </a>{' '}
              e <br /> saiba como tratamos seus dados pessoais e conheça nossos{' '}
              <br /> Termos e Condições de uso do site e Política de
              Privacidade.
            </S.CompanyLawMessage>
            <S.FooterBottomMessage>
              { appConfig.COPY_RIGHTS } – CNPJ 61.550.141/0001-72 - Código SUSEP{' '}
              {window.innerWidth <= mobileMinWidthInt && <br />} 518-5. Processo
              SUSEP nº&nbsp;15414.100331/2004-96 e 15414.901089/2015-23. O
              registro do produto é automático e não representa aprovação ou
              recomendação por parte da Susep. Para informações adicionais ou
              esclarecimento de dúvidas, entre em{' '}
              {window.innerWidth <= mobileMinWidthInt && <br />} contato com seu
              Corretor de seguros ou, se preferir, utilize nosso{' '}
              {window.innerWidth <= mobileMinWidthInt && <br />} canal de
              comunicação com o Segurado(a):
              <a
                target="_blank"
                href="mailto:faleconosco@yelum.com.br"
                rel="noreferrer"
              >
                {' '}
                faleconosco@yelum.com.br
              </a>
              . Caso não fique satisfeito com{' '}
              {window.innerWidth <= mobileMinWidthInt && <br />} a solução do
              nosso time de atendimento, poderá recorrer a{' '}
              {window.innerWidth <= mobileMinWidthInt && <br />} Ouvidoria. A
              Ouvidoria {appConfig.COMPANY_NAME} atua com Ouvidor independente e{' '}
              {window.innerWidth <= mobileMinWidthInt && <br />} com autonomia
              de decisão. O regulamento da Ouvidoria está{' '}
              {window.innerWidth <= mobileMinWidthInt && <br />} disponível no
              site{' '}
              <a
                target="_blank"
                onClick={() => handleGoogleEvent('www.yelumseguros.com.br')}
                href={getCompanyWebsite()}
                rel="noreferrer"
              >
                www.yelumseguros.com.br
              </a>
              , onde também é {window.innerWidth <= mobileMinWidthInt && <br />}{' '}
              possível postar seu recurso. Se preferir, o recurso também poderá{' '}
              {window.innerWidth <= mobileMinWidthInt && <br />} ser feito pelo
              telefone 0800-740-3994 ou ainda por carta, para a{' '}
              {window.innerWidth <= mobileMinWidthInt && <br />} Rua Dr. Geraldo
              Campos Moreira, nº 110 - São Paulo/SP – CEP{' '}
              {window.innerWidth <= mobileMinWidthInt && <br />} 04571-020 – a/c
              Ouvidoria
            </S.FooterBottomMessage>
          </S.Content>
        </S.LimitWrapper>
      </S.Wrapper>
    </footer>
  );
}
