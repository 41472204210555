/* eslint-disable prefer-destructuring */
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect } from 'react';
import { useBroker } from 'contexts/Broker';
import { useWindowSize } from 'helpers/custom-hooks';

import { mobileMinWidthInt } from 'styles/variables';
import { BrokerCard } from 'components/Card/BrokerCard';

import { LOCAL_BROKER, LOCAL_CONTRACT_COMPLETED, LOCAL_CPF } from 'config';
import { formatCPF } from 'helpers/format';
import { sendBrokerLeadNotification } from 'api/services/broker';
import axios from 'axios';
import { detectBrowser } from 'helpers/detectBrowser';
import * as S from './styles';
import appConfig from '../../assets/settings/appConfig.UAT.json'

export const Success = () => {
  const controller = new AbortController();
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const { broker } = useBroker();
  const [width] = useWindowSize();

  const fetchSendBrokerLeadNotification = async () => {
    const brokerData = localStorage.getItem(LOCAL_BROKER)
    const brokerDataParsed = JSON.parse(brokerData!)
    const cpf = localStorage.getItem(LOCAL_CPF)
    const brokerDataLocalStorage = window.localStorage.getItem(LOCAL_BROKER);

    const payload: any = {
      broker: {
        brokerCode: brokerDataParsed.broker.brokerCode,
        brokerBranchCode: brokerDataParsed.broker.brokerBranchCode,
        internalBranchCode: brokerDataParsed.broker.internalBranchCode
      },
      brokerUser: 'enim',
      brokerToken: brokerDataParsed.brokerToken,
      affinity: {
        affinityAccountCode:
          brokerDataParsed.affinity.affinityAccountCode,
        affinityAccountBranchCode:
          brokerDataParsed.affinity.affinityAccountBranchCode,
        affinityCommPlanCode:
          brokerDataParsed.affinity.affinityCommPlanCode
      },
      commercialProductCode: String(
        brokerDataParsed.commercialProductCode
      ),
      documentNumber: formatCPF(cpf!)
    }
    const response = await sendBrokerLeadNotification(payload, controller.signal, source.token)

    if (response.status === 200) {
      window.localStorage.removeItem(LOCAL_CONTRACT_COMPLETED)
      localStorage.removeItem(LOCAL_CPF)
      source.cancel()
      controller.abort()
    }
  }

  const beforeUnload = useCallback((e: any, isMobile: boolean) => {
    e.preventDefault()
    e.stopPropagation()
    e.returnValue = ''

    const contractCompleted = window.localStorage.getItem(
      LOCAL_CONTRACT_COMPLETED
    )

    if (contractCompleted === 'false' && !isMobile) {
      fetchSendBrokerLeadNotification()
    }

    if (contractCompleted === 'false' && isMobile) {
      fetchSendBrokerLeadNotification()
    }
  }, [])

  useEffect(() => {
    const isSafari = detectBrowser().toLowerCase().includes('safari')
    let sended = false

    if (!sended && window.innerWidth > mobileMinWidthInt) {
      sended = true
      window.addEventListener('beforeunload', (e) => beforeUnload(e, false))
    }

    if (!sended && window.innerWidth <= mobileMinWidthInt && !isSafari) {
      sended = true
      window.addEventListener('visibilitychange', (e) => beforeUnload(e, true))
    }

    if (!sended && window.innerWidth <= mobileMinWidthInt && isSafari) {
      sended = true
      window.addEventListener('blur', (e) => beforeUnload(e, true))
    }

    return () => {
      window.removeEventListener('beforeunload', (e) => beforeUnload(e, false))
      window.removeEventListener('blur', (e) => beforeUnload(e, true))
      // window.removeEventListener('visibilitychange', (e) => beforeUnload(e, true))
      source.cancel()
      controller.abort()
    }
  }, [])

  return (
    <S.Container>
      <S.Wrapper>
        <S.Title>Agradecemos o interesse!</S.Title>
        <S.Subtitle>
          Você está muito próximo de ter o Seguro Auto {appConfig.COMPANY_NAME} para
          proteger seu veículo!
        </S.Subtitle>
        <S.Subtitle
          style={{ marginTop: width <= mobileMinWidthInt ? '16px' : '28px' }}
        >
          Em breve o(a) corretor(a) entrará em contato com você para finalizar a
          contratação.
        </S.Subtitle>
        <S.ContentContainer>
          <S.Illustration />

          {broker?.broker && (
            <S.BrokerContainer>
              <BrokerCard />
            </S.BrokerContainer>
          )}
        </S.ContentContainer>
      </S.Wrapper>
    </S.Container>
  );
};
