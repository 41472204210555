/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-relative-packages */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable import/order */
/* eslint-disable react/display-name */
import React from 'react'

import { IsMobile } from 'helpers/custom-hooks'

import '../../../../node_modules/slick-carousel/slick/slick.css'
import '../../../../node_modules/slick-carousel/slick/slick-theme.css'
import appConfig from '../../../assets/settings/appConfig.UAT.json'

import * as S from './styles'

const reasons = [
  {
    icon: <S.IconCarPNG />,
    title: 'Proteção e tranquilidade',
    description:
      'Curta seu automóvel com a segurança, liberdade e proteção que você necessita'
  },
  {
    icon: <S.ToolIcon />,
    title: 'Comodidade',
    description: (
      <>
        Com as assistências {appConfig.COMPANY_NAME} você terá à disposição dezenas de serviços para te ajudar no seu
        dia-a-dia
      </>
    )
  },
  {
    icon: <S.PriceIcon />,
    title: 'Excelência e qualidade',
    description:
      `O seguro auto foi pensado no seu bolso e tem toda a tradição e excelência da ${appConfig.COMPANY_FULL_NAME}`
  }
]

const WhyContract = () => {
  const isMobile = IsMobile()

  const settings = {
    dots: true,
    infinite: false,
    centerMode: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    appendDots: (dots: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined) => <ul>{dots}</ul>,
    customPaging: () => <S.Bullet />
  }

  return (
    <S.Wrapper>
      {isMobile ? (
        <S.SliderContainer>
          <S.Header>
            <S.Title>Por que contratar um seguro auto com a {appConfig.COMPANY_NAME}?</S.Title>
            <S.ThinkingImage />
          </S.Header>
          <S.ReasonSlider {...settings}>
            {reasons.map((reason, index) => (
              <S.ReasonCard key={index}>
                <S.IconContainer>{reason?.icon}</S.IconContainer>
                <S.ReasonName>{reason.title}</S.ReasonName>
                <S.ReasonDescription>{reason.description}</S.ReasonDescription>
              </S.ReasonCard>
            ))}
          </S.ReasonSlider>
        </S.SliderContainer>
      ) : (
        <>
          <S.ImageContainer>
            <S.ThinkingImage />
          </S.ImageContainer>
          <S.InfoContainer>
            <S.Title>Por que contratar um seguro auto com a {appConfig.COMPANY_NAME}?</S.Title>
            <S.ReasonContainer>
              {reasons.map((reason, index) => (
                <S.ReasonCard key={index}>
                  <S.IconContainer>{reason?.icon}</S.IconContainer>
                  <S.ReasonName>{reason.title}</S.ReasonName>
                  <S.ReasonDescription>
                    {reason.description}
                  </S.ReasonDescription>
                </S.ReasonCard>
              ))}
            </S.ReasonContainer>
          </S.InfoContainer>
        </>
      )}
    </S.Wrapper>
  )
}

export default WhyContract
