import { getActivateClaimLink, getCustomerSpaceLink, getGlassAssistanceLink, getVehicleFranchiseLink, getVehicleInspectionLink, getWhatsappLink } from 'helpers/links';
import React from 'react';

import * as S from './styles';
import appConfig from '../../assets/settings/appConfig.UAT.json'

interface IQuestion {
  question: React.ReactNode;
  answer: React.ReactNode;
}

const Questions: IQuestion[] = [
  {
    question: 'O que significa Cobertura Básica Compreensiva?',
    answer: (
      <>
        Indeniza o Segurado pelos prejuízos ou danos materiais que venham a ocorrer no
        veículo em decorrência de: Colisão, abalroamento ou capotagem acidentais;
        Roubo ou furto total ou parcial; Queda acidental de precipício ou de pontes; Raio, incêndio ou explosão acidentais;
        Enchentes ou inundação em água doce; Chuva de granizo, furacão e terremoto.
      </>
    ),
  },
  {
    question: 'O que significa franquia?',
    answer: (
      <>
        Franquia do veículo segurado é uma taxa paga pelo segurado,
        que corresponde a sua participação obrigatória no valor dos prejuízos causados em casos de sinistro…
        <S.Link href={getVehicleFranchiseLink()}>
          {'Continuar lendo >'}
        </S.Link>
      </>
    ),
  },
  {
    question: 'Como acesso minha apólice e cobertura após a contratação do seguro?',
    answer: (
      <>
        Você receberá sua apólice pelo e-mail informado na contratação do seguro.
        Para ver as coberturas acesse a área de Coberturas no Espaço Cliente.
        <S.Link href={getCustomerSpaceLink()}>
          {'Acessar Espaço Cliente >'}
        </S.Link>
      </>
    ),
  },
  {
    question: 'E se eu precisar alterar alguma informação do meu seguro?',
    answer: (
      <>
        Entre em contato com o seu corretor para que ele faça a alteração necessária.
      </>
    ),
  },
  {
    question: 'Como acionar o seguro em caso de sinistro?',
    answer: (
      <>
        O sinistro é a ocorrência do evento para o qual foi contratado o seguro.
        Você pode acionar seu seguro de forma simples e rápida, avisando o sinistro pelo nosso site.
        <S.Link href={getActivateClaimLink()}>
          {'Avisar sinistro >'}
        </S.Link>
      </>
    ),
  },
  {
    question: 'Como acionar Assistência de Vidros?',
    answer: (
      <>
        1º Passo – Acione a {appConfig.COMPANY_FULL_NAME} Você deve acionar a {appConfig.COMPANY_NAME} para comunicar o ocorrido, pelo site ,
        telefone 0800 701 4120 ou através da Abertura Online (clique aqui) .
        Você precisará informar o nome completo e CPF do segurado, placa e chassi do veículo…
        <S.Link href={getGlassAssistanceLink()}>
          {'Continuar lendo >'}
        </S.Link>
      </>
    ),
  },
  {
    question: 'Como funciona a renovação?',
    answer: (
      <>
        A renovação pode ser automática ou através do corretor que entrará em contato quando
        a renovação estiver próxima. Converse com seu corretor para ter mais detalhes.
      </>
    ),
  },
  {
    question: 'E se eu me arrepender da compra?',
    answer: (
      <>
        O contrato de seguro pode ser cancelado a qualquer momento.
        Caso você desista desta contratação em até 7 (sete) dias, nós estornaremos o valor integral pago.
        O cancelamento pode ser feito pelo telefone 0800 726-1981.
      </>
    ),
  },
  {
    question:
      `Como posso entrar em contato com a ${appConfig.COMPANY_FULL_NAME} ?`,
    answer: (
      <>
        Entre em contato através do nosso Whatsapp:
        <S.Link href={getWhatsappLink()}>
          {'Abrir conversa do Whatsapp >'}
        </S.Link>
      </>
    ),
  },
  {
    question: 'Informações sobre vistoria do veículo:',
    answer: (
      <>
        Para acessar informações sobre a vistoria acesse:
        <S.Link href={getVehicleInspectionLink()}>
          {'Continuar lendo >'}
        </S.Link>
      </>
    ),
  },
];

export { Questions };
